
    .page-title {
        font-size: 20px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333;
        margin: 20px 0;
        line-height: 1;
    }
    .goodsPurchase-container {
        background: #fff;
        padding: 0 20px 20px;
        min-height: calc(100vh - 194px);
        /*height: calc(100vh - 189px);*/
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    /deep/ .commodityTab {
        .el-tabs__header {
            margin-bottom: 0;
            .el-tabs__nav-wrap {
                &:after {
                    content: none;
                }
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                color: #666;
                font-weight: 400;
                &.is-active, &:hover {
                    color: #FD4446;
                }
            }
            .el-tabs__active-bar {
                background-color: #FD4446;
            }
        }
    }
